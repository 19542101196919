<template>
  <div>
    <vs-button
      class="mb-3 lg:mb-0"
      style="margin-right: 10px"
      @click="editBankDetails"
      >Edit Payment Details</vs-button
    >

    <vs-popup
      class="holamundo"
      title="Update Bank Details"
      :active.sync="bankDetailPopup"
    >
      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input
              :danger="errors.first('accountHolderName') ? true : false"
              :success="
                !errors.first('accountHolderName') &&
                bankDetails.accountHolderName != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="'required'"
              data-vv-validate-on="blur"
              data-vv-as="Account Holder Name"
              label-placeholder="Account Holder Name"
              name="accountHolderName"
              placeholder="Account Holder Name"
              v-model="bankDetails.accountHolderName"
              class="w-full mt-6"
            />
            <span class="text-danger text-sm">
              {{ errors.first("accountHolderName") }}
            </span>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input
              :danger="errors.first('bsb') ? true : false"
              :success="!errors.first('bsb') && bankDetails.bsb != ''"
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="'required|digits:6'"
              data-vv-validate-on="blur"
              data-vv-as="BSB"
              label-placeholder="BSB"
              name="bsb"
              placeholder="BSB"
              v-model="bankDetails.bsb"
              class="w-full mt-6"
            />
            <span class="text-danger text-sm">
              {{ errors.first("bsb") }}
            </span>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input
              :danger="errors.first('accountNumber') ? true : false"
              :success="
                !errors.first('accountNumber') &&
                bankDetails.accountNumber != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="'required'"
              data-vv-validate-on="blur"
              data-vv-as="Account Number"
              label-placeholder="Account Number"
              name="accountNumber"
              placeholder="Account Number"
              v-model="bankDetails.accountNumber"
              class="w-full mt-6"
            />
            <span class="text-danger text-sm">
              {{ errors.first("accountNumber") }}
            </span>
          </div>
        </vs-col>
      </vs-row>

      <vs-row>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-button @click="saveBankDetails" :disabled="!validateForm"
              >Save Details</vs-button
            >
          </div>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import { Validator } from "vee-validate";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      bankDetailPopup: false,
      bankDetails: {
        accountHolderName: "",
        bsb: "",
        accountNumber: "",
      },
      userDetail: {},
    };
  },
  methods: {
    ...mapActions("clinic", ["updateClinicBankDetails"]),
    editBankDetails() {
      const url = `/${
        this.$store.state.AppActiveUser.userType === "doctorClinic"
          ? "doctor-clinic"
          : "clinic"
      }/setup-bank-detail`;
      this.$router.push(url);
      // this.errors.clear();
      // this.$vs.loading();
      // this.$store
      //   .dispatch(
      //     "general/getUserDetailForProfile",
      //     this.$store.state.AppActiveUser._id
      //   )
      //   .then((response) => {
      //     this.fetchedData = response.data.data;
      //     this.bankDetails = this.fetchedData.financialDetails
      //       ? {
      //           accountHolderName: this.fetchedData.financialDetails
      //             .accountHolderName,
      //           bsb: this.fetchedData.financialDetails.bsb,
      //           accountNumber: this.fetchedData.financialDetails.accountNumber,
      //         }
      //       : {
      //           accountHolderName: "",
      //           bsb: "",
      //           accountNumber: "",
      //         };
      //     this.$vs.loading.close();
      //     this.bankDetailPopup = true;
      //   })
      //   .catch((err) => {
      //     this.$vs.notify({
      //       title: "Failed",
      //       text: "Oops ! something went wrong. Please try later.",
      //       color: "danger",
      //     });
      //     this.$vs.loading.close();
      //     this.bankDetailPopup = false;
      //   });
    },

    async saveBankDetails() {
      const self = this;
      let isValid = await self.$validator.validate();
      if (isValid) {
        self.$vs.loading();
        await self
          .updateClinicBankDetails(self.bankDetails)
          .then((res) => {
            self.$vs.notify({
              title: "Success",
              text: "Bank details updated successfully !",
              color: "success",
            });
            self.$vs.loading.close();
            self.bankDetailPopup = false;
            self.$emit("forceRerender");
          })
          .catch((err) => {
            let errMessage = err.data.message;
            if (
              err.data.message ===
              "Invalid routing number for AU. The number must contain both the bank code and the branch code, and should be in the format xxxxxx."
            )
              errMessage =
                "Invalid BSB for AU. The number must contain both the bank code and the branch code, and should be in the format xxxxxx.";
            self.$vs.notify({
              title: "Failed",
              text: errMessage,
              color: "danger",
            });
            self.$vs.loading.close();
          });
      }
    },
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    },
  },
};
</script>
