<template>
  <div>
  <vs-popup :active.sync="isPopupActive" title="Add Card">
      <stripe-elements
        ref="elementsRef"
        :pk="stripePublishableKey"
        :amount="0"
        locale="en"
        @token="tokenCreated"
        @loading="loading = $event"
      >
      </stripe-elements>
    <vs-button class="mb-2" @click="submit">Save Card Details</vs-button>
  </vs-popup>
  </div>

</template>

<script>
  import StripeElements from '../order-center/StripeElements'
  import {mapActions} from "vuex";
  export default {
        name: "CardPopup",
        data(){
          return {
            stripePublishableKey:process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,

          }
        },
        props:['popUpCard'],
    components:{
          StripeElements
       },
      computed:{
          isPopupActive:{
            get(){
              return this.popUpCard
            },
            set(value){
              return value
            }
          }
      },
      methods:{
        ...mapActions('general',['saveCardDetail']),

        onClickCloseButton() {
          this.$emit('closePopup')
        },
        onCardAddSuccess(){
          this.$emit('cardAddSuccess')
        },
        tokenCreated (token) {
          this.token = token;
          // for additional charge objects go to https://stripe.com/docs/api/charges/object
          this.sendToServer(this.token.id);
        },
        async sendToServer (token) {
          var self =this;
          // Send to charge to your backend server to be processed
          this.$vs.loading();
          // Documentation here: https://stripe.com/docs/api/charges/create
          this.saveCardDetail({token:token}).then((response) => {
            this.$vs.notify({
              title: response.data.title,
              text: response.data.message,
              color: 'success',
              iconPack: 'feather',
              icon: 'icon-alert-circle'
            })
            this.$vs.loading.close();
            self.onCardAddSuccess();
          }).catch((error) =>{
            this.$vs.loading.close();
            this.$vs.notify({
              title: error.data.title,
              text: error.data.message,
              color: 'danger',
              iconPack: 'feather',
              icon: 'icon-alert-circle'
            })
          });
        },
        submit(){
          this.$refs.elementsRef.submit();
        }
      },
      mounted(){
        const next =this.$children[0].$refs.btnclose;
        next.$el.addEventListener('click', this.onClickCloseButton, false);
      },
    }
</script>

<style scoped>

</style>
