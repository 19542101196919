var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"profile-page"}},[_c('vx-card',{staticClass:"mt-base"},[_c('ul',{staticClass:"page-suggestions-list"},[_c('li',{staticClass:"page-suggestion flex flex-col lg:flex-row items-center mb-4"},[_c('div',{staticClass:"mr-3"},[_c('div',{staticClass:"profile-header-image"},[(_vm.activeUserInfo.photoURL)?_c('img',{key:"onlineImg",staticClass:"img-fluid",attrs:{"src":_vm.activeUserInfo.photoURL,"alt":"user-img"}}):_vm._e()])]),_c('div',{staticClass:"leading-tight"},[_c('p',{staticClass:"font-medium"},[_vm._v("My Details")]),_c('span',{staticClass:"text-xs"},[_vm._v("Manage account details")])]),_c('div',{staticClass:"ml-auto"},[_c('div',{staticClass:"flex flex-wrap"},[(
                (_vm.userDetail.userType === 'clinic' &&
                  _vm.bankDetails.accountHolderName !== '') ||
                (_vm.userDetail.userType === 'clinic' &&
                  _vm.userDetail.paymentDetails.cardId !== '') ||
                (_vm.userDetail.userType === 'doctorClinic' &&
                  _vm.userDetail.paymentDetails.cardId !== '')
              )?_c('ClinicEditBankDetails',{on:{"forceRerender":_vm.forceRerender}}):_vm._e(),_c('ProfileChangePassword'),(_vm.edit)?_c('vs-button',{staticClass:"mb-3 lg:mb-0",staticStyle:{"margin-right":"10px"},attrs:{"icon-pack":"feather","icon":"icon-x","color":"danger","type":"filled"},on:{"click":function($event){return _vm.cancelEdit()}}},[_vm._v("Cancel Edit")]):_c('vs-button',{staticStyle:{"margin-right":"10px"},attrs:{"icon-pack":"feather","icon":"icon-edit","color":"primary","type":"filled"},on:{"click":function($event){return _vm.editProfile()}}},[_vm._v("Edit Profile")]),(_vm.showAddCard())?_c('vs-button',{attrs:{"icon-pack":"feather","icon":"icon-edit","color":"primary","type":"filled"},on:{"click":function($event){return _vm.$router.push({
                  name:
                    _vm.$store.state.AppActiveUser.userType === 'doctorClinic'
                      ? 'DoctorClinicAddCard'
                      : 'ClinicAddCard',
                })}}},[_vm._v("Add Card")]):_vm._e()],1)])])])]),(_vm.cardDetails)?_c('div',{staticClass:"stripe-card mt-base"},[_c('StripeCardDetails',{attrs:{"cardDetails":_vm.cardDetails},on:{"onRemoveCardSuccess":_vm.removeCardSuccess}})],1):_vm._e(),(_vm.edit)?_c('vx-card',{staticClass:"mt-base",attrs:{"title":"Edit Profile Detail"}},[_c('ClinicProfileEdit',{on:{"cancelEdit":_vm.cancelEdit}})],1):_c('vx-card',{staticClass:"mt-base",attrs:{"title":"Profile Details"}},[(_vm.renderComponent)?_c('ProfileDetail'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }