<template>
  <vs-button class="w-full lg:flex-1" @click="changePasswordHandler">Change Password</vs-button>
</template>

<script>
export default {
  name: "ProfileChangePassword",
  methods: {
    changePasswordHandler() {
      let user = this.$store.state.AppActiveUser.userRole;
      const role = localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`
      );
      if (role && role.toUpperCase() === "org owner".toUpperCase()) {
        this.$router.push({ name: "org-owner-change-password" });
      } else {
        if (user === "superAdmin") {
          this.$router.push("/super-admin/change-password");
        } else if (user === "subFranchise") {
          this.$router.push("/franchise/change-password");
        } else if (user === "subClinic") {
          this.$router.push("/clinic/change-password");
        } else if (user === "doctorClinic") {
          this.$router.push("/doctor-clinic/change-password");
        } else {
          this.$router.push("/" + user + "/change-password");
        }
      }
    },
  },
};
</script>
