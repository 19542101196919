<template>
  <div id="profile-page">
    <!-- My Details -->

    <vx-card class="mt-base">
      <ul class="page-suggestions-list">
        <li class="page-suggestion flex flex-col lg:flex-row items-center mb-4">
          <div class="mr-3">
            <div class="profile-header-image">
              <img
                v-if="activeUserInfo.photoURL"
                key="onlineImg"
                :src="activeUserInfo.photoURL"
                alt="user-img"
                class="img-fluid"
              />
            </div>
          </div>
          <div class="leading-tight">
            <p class="font-medium">My Details</p>
            <span class="text-xs">Manage account details</span>
          </div>
          <div class="ml-auto">
            <div class="flex flex-wrap">
              <ClinicEditBankDetails
                @forceRerender="forceRerender"
                v-if="
                  (userDetail.userType === 'clinic' &&
                    bankDetails.accountHolderName !== '') ||
                  (userDetail.userType === 'clinic' &&
                    userDetail.paymentDetails.cardId !== '') ||
                  (userDetail.userType === 'doctorClinic' &&
                    userDetail.paymentDetails.cardId !== '')
                "
              />
              <ProfileChangePassword />
              <vs-button
                class="mb-3 lg:mb-0"
                style="margin-right: 10px"
                v-if="edit"
                icon-pack="feather"
                icon="icon-x"
                color="danger"
                type="filled"
                @click="cancelEdit()"
                >Cancel Edit</vs-button
              >
              <vs-button
                v-else
                style="margin-right: 10px"
                icon-pack="feather"
                icon="icon-edit"
                color="primary"
                type="filled"
                @click="editProfile()"
                >Edit Profile</vs-button
              >
              <!--              <vs-button-->
              <!--                icon-pack="feather"-->
              <!--                icon="icon-edit"-->
              <!--                color="primary"-->
              <!--                type="filled"-->
              <!--                @click="addCard()" v-if="!cardDetails">Add Card</vs-button>-->
              <vs-button
                icon-pack="feather"
                icon="icon-edit"
                color="primary"
                type="filled"
                @click="
                  $router.push({
                    name:
                      $store.state.AppActiveUser.userType === 'doctorClinic'
                        ? 'DoctorClinicAddCard'
                        : 'ClinicAddCard',
                  })
                "
                v-if="showAddCard()"
                >Add Card</vs-button
              >
            </div>
          </div>
        </li>
      </ul>
    </vx-card>
    <div v-if="cardDetails" class="stripe-card mt-base">
      <StripeCardDetails
        :cardDetails="cardDetails"
        @onRemoveCardSuccess="removeCardSuccess"
      />
    </div>
    <!--   <CardPopup :popUpCard="popUpCard" @closePopup="closePopup" @cardAddSuccess="cardAddSuccess"></CardPopup>-->

    <!-- Edit Profile Detail -->
    <vx-card v-if="edit" title="Edit Profile Detail" class="mt-base">
      <ClinicProfileEdit @cancelEdit="cancelEdit" />
    </vx-card>

    <!-- Profile Detail -->
    <vx-card v-else title="Profile Details" class="mt-base">
      <ProfileDetail v-if="renderComponent" />
    </vx-card>
  </div>
</template>

<script>
import ProfileDetail from "@/views/components/profile/ProfileDetail";
import ClinicProfileEdit from "@/views/components/profile/ClinicProfileEdit";
import ProfileChangePassword from "@/views/components/profileChangePassword";
import ClinicEditBankDetails from "@/views/clinic/ClinicEditBankDetails.vue";
import CardPopup from "../components/profile/CardPopup";
import { mapActions } from "vuex";
import StripeCardDetails from "../components/profile/StripeCardDetails";
import { EventBus } from "../../main";

export default {
  components: {
    CardPopup,
    ProfileDetail,
    StripeCardDetails,
    ClinicProfileEdit,
    ProfileChangePassword,
    ClinicEditBankDetails,
  },
  data() {
    return {
      edit: false,
      popUpCard: false,
      cardDetails: null,
      bankDetails: {
        accountHolderName: "",
        accountHolderEmail: "",
      },
      userDetail: {},
      renderComponent: true,
    };
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  methods: {
    ...mapActions("general", ["fetchCardDetails"]),
    async fetchCardDetail() {
      const cardDetails = await this.fetchCardDetails();
      this.cardDetails = cardDetails.data.data;
    },
    async cardAddSuccess() {
      await this.fetchCardDetail();
      this.popUpCard = false;
    },
    showAddCard() {
      if (
        this.cardDetails &&
        this.$store.state.AppActiveUser.userType !== "doctorClinic"
      ) {
        return true;
      }
      return false;
    },
    removeCardSuccess() {
      this.cardDetails = null;
    },
    editProfile() {
      this.userDetail = this.$store.state.AppActiveUser;
      this.edit = true;
    },
    cancelEdit() {
      this.userDetail = this.$store.state.AppActiveUser;
      this.edit = false;
    },
    addCard() {
      this.popUpCard = true;
    },
    closePopup() {
      this.popUpCard = false;
    },
    changePasswordHandler() {
      let user = this.$store.state.AppActiveUser.userRole;
      if (user === "superAdmin") {
        this.$router.push("/super-admin/change-password");
      } else {
        this.$router.push("/" + user + "/change-password");
      }
    },
    forceRerender() {
      this.renderComponent = false;

      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
  },
  created() {
    this.userDetail = this.$store.state.AppActiveUser;
    if (
      this.userDetail.paymentDetails &&
      this.userDetail.paymentDetails.cardId
    ) {
      this.fetchCardDetail();
    }

    this.bankDetails = this.userDetail.financialDetails
      ? this.userDetail.financialDetails
      : {
          accountHolderName: "",
          accountHolderEmail: "",
        };
  },
  beforeDestroy() {
    EventBus.$emit("closePopup");
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/pages/profile.scss";
.stripe-card .remove-card:hover {
  text-decoration: underline;
}
</style>
